import React, { useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "@videojs/http-streaming";
import qualityPlugin from "@silvermine/videojs-quality-selector";
import "@silvermine/videojs-quality-selector/dist/css/quality-selector.css";

const baseURL = "https://bj-1.cdn.yrmusix.com/";
const releaseKey = "bodyjam104";
const fallbackURL = "";

function App() {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    if (
      window.location.href.includes(".pages.dev") ||
      window.location.href.includes(".cdn.yrmusix.com")
    )
      window.location.href = "https://cdn.yrmusix.com";

    if (!releaseKey) {
      if (fallbackURL) window.location.href = fallbackURL;
      return;
    }

    qualityPlugin(videojs);
    const videoElement = document.createElement("video-js");
    videoElement.classList.add("vjs-big-play-centered");
    videoRef.current.appendChild(videoElement);

    playerRef.current = videojs(videoElement, {
      controls: true,
      responsive: true,
      autoplay: true,
      fluid: true,
      controlBar: {
        children: [
          "playToggle",
          "progressControl",
          "volumePanel",
          "qualitySelector",
          "fullscreenToggle"
        ]
      }
    });

    playerRef.current.src([
      {
        src: `${baseURL}hls/${releaseKey}/720p.m3u8`,
        type: "application/x-mpegURL",
        selected: true,
        label: "720p"
      },
      {
        src: `${baseURL}hls/${releaseKey}/480p.m3u8`,
        type: "application/x-mpegURL",
        label: "480p"
      },
      {
        src: `${baseURL}hls/${releaseKey}/360p.m3u8`,
        type: "application/x-mpegURL",
        label: "360p"
      }
    ]);

    playerRef.current.addRemoteTextTrack({
      src: `${baseURL}hls/${releaseKey}/subtitle.vtt`,
      mode: "showing",
      default: true,
      srclang: "en",
      label: "English"
    });

    playerRef.current.responsive(true);
    playerRef.current.fluid(true);

    playerRef.current.ready(function () {
      playerRef.current.playsinline(true);
    });

    return () => void playerRef.current.dispose();
  }, []);

  return (
    <div className="ratio-16-9">
      <div className="absolute">
        <div className="relative">
          <div ref={videoRef} />;
        </div>
      </div>
    </div>
  );
}

export default App;
